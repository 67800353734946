import React from 'react';

import * as styles from './Footer.module.css';

const Footer = () => {
  return (
    <div className={styles.container}>
     &copy; Ahmetcan ÖZCAN - 2021
    </div>
  )
};

export default Footer;
      